import { Button } from "@chakra-ui/react"
import useStore from "../../../../store"

export default function UnitFilterIndicators({onClose}) {
  const setAmUnitsTableParams = useStore((state) => state.setAmUnitsTableParams)
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const selectedDealerUnitsScopeLabel = useStore((state) => state.selectedDealerUnitsScopeLabel)

  const updateFilterParams = (paramsToRemove) => {
    const updatedFilterParams = { ...amUnitsTableParams }
    paramsToRemove.forEach(param => delete updatedFilterParams[param])
    setAmUnitsTableParams(updatedFilterParams)
  }

  const handleResetScope = () => {
    updateFilterParams(['filter[scope]'])
    onClose()
  }

  return(
    <div className="flex justify-start w-full gap-2 mb-2 mt-2">
      {amUnitsTableParams['filter[scope]'] && amUnitsTableParams['filter[scope]'] != 'current' && (
        <Button
          variant="outline"
          borderColor={'white'}
          color={'white'}
          border={'2px'}
          bg="transparent"
          onClick={handleResetScope}
          sx={{
            borderRadius: 'full'
          }}
        >
          {selectedDealerUnitsScopeLabel || 'No filter selected'}
        </Button>
      )}
  </div>
  )
}