import useStore from "../../../store"
import TableSortingByHeader from "../../common/TableSortingByHeader"
import { useIsMobile } from "../../../hooks/useIsMobile"
import HeaderWithoutSorting from "../../common/HeaderWithoutSorting"

export default function DealersTableHeader({isNearbyDealersRow=null, style}) {
  const setDealerFilterAttribute = useStore((state) => state.setDealerFilterAttribute)
  const setDealerFilterDirection = useStore((state) => state.setDealerFilterDirection)
  const dealerFilterAttribute = useStore((state) => state.dealerFilterAttribute)
  const dealerFilterDirection = useStore((state) => state.dealerFilterDirection)
  const dealerTableFilterParams = useStore((state) => state.dealerTableFilterParams)
  const isMobile = useIsMobile()

  const scopeHeaders = {
    with_untouched_units: "Untouched 40+ Days",
    with_sold_units: "Sold Units",
    with_repo_units: "Repo Units",
    with_past_due_units: "Past Due Units",
    untouched_past_week: "Last Touched"
  }

  const renderHeader = (title, attribute, width, titleStyles, style) => {
    if (isNearbyDealersRow) {
      return (
        <HeaderWithoutSorting
          title={title}
          width={width}
          style={style}
        />
      )
    } else {
      return (
        <TableSortingByHeader
          title={title}
          attribute={attribute}
          filterAttribute={dealerFilterAttribute}
          filterDirection={dealerFilterDirection}
          setFilterAttribute={setDealerFilterAttribute}
          setFilterDirection={setDealerFilterDirection}
          width={width}
          titleStyles={titleStyles}
        />
      )
    }
  }

  return(
    <thead className={`sticky ${style} bg-grey-900 w-full`}>
      <tr>
        {renderHeader('Dealer', 'name', 'w-full')}
        {!isNearbyDealersRow && scopeHeaders[dealerTableFilterParams.scope] && (
          <HeaderWithoutSorting
            width={isMobile ? 'w-16' : 'w-24'}
            title={scopeHeaders[dealerTableFilterParams.scope]}
            style={'justify-center'}
          />
        )}
        {(dealerTableFilterParams['filter[distance]'] || isNearbyDealersRow) && (
          <HeaderWithoutSorting
            width={isMobile ? 'w-16' : 'w-24'}
            title="Distance"
            style={'justify-center'}
          />
        )}
        {renderHeader('Util', 'utilization', isMobile ? 'w-16' : 'w-24', 'justify-center', 'justify-center')}
        {renderHeader('NAPs', 'nap_count', isMobile ? 'w-16' : 'w-24', 'justify-center', 'justify-center')}
        {renderHeader('Units', 'unit_count', isMobile ? 'w-16' : 'w-24', 'justify-center', 'justify-center')}
      </tr>
    </thead>

  )
}