import useStore from "../../../store"
import Spinner from "../../common/Spinner"
import UnitTable from "./UnitTable"
import NoCurrentUnits from "./NoCurrentUnits"
import { useState } from "react"
import SearchBar from "../../common/SearchBar"
import UnitTableFilterButtons from "../../AccountManager/Dealer/Units/UnitTableFilterButtons"
import { useTranslation } from "react-i18next"
import { FaUndoAlt } from "react-icons/fa"
import UnitsFilterByDrawer from "../../AccountManager/Dealer/Units/UnitsFilterByDrawer"
import { IoFilterSharp } from "react-icons/io5"
import { useDisclosure } from "@chakra-ui/react"
import UnitFilterIndicators from "../../AccountManager/Dealer/Units/UnitFilterIndicators"
import { useIsMobile } from "../../../hooks/useIsMobile"

export default function CurrentUnits() {
  const units = useStore((state) => state.units)
  const unitsFetching = useStore((state) => state.unitsFetching)
  const user = useStore((state) => state.user)
  const { t } = useTranslation()
  const amUnitsTableParams = useStore((state) => state.amUnitsTableParams)
  const setAmUnitsTableParams = useStore((state) => state.setAmUnitsTableParams)
  const dealerUnitsSearchBarValue = useStore((state) => state.dealerUnitsSearchBarValue)
  const setDealerUnitsSearchBarValue = useStore((state) => state.setDealerUnitsSearchBarValue)
  const dealer = useStore((state) => state.dealer)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useIsMobile()

  const unitsBody = () => {
    if (units?.length > 0) {
      if(units[0]?.attributes['dealer-id'] == dealer?.id) {
        return (<UnitTable searchTerm={dealerUnitsSearchBarValue} setSearchTerm={setDealerUnitsSearchBarValue}/>)
      } else {
        return (<Spinner />)
      }
    } else if (unitsFetching) {
      return (<Spinner />)
    } else {
      return (<NoCurrentUnits />)
    }
  }

  const handleReset = () => {
    setAmUnitsTableParams('')
    setDealerUnitsSearchBarValue('')
  }

  const currentScope = amUnitsTableParams['filter[scope]']

  const stickyStyles = user?.type === 'AccountManager'
  ? `top-10 z-50 ${isMobile ? 'pt-6' : ''}`
  : `top-0 z-30`

  return (
    <div className="flex flex-col items-center sm:mt-0 w-full bg-grey-900">
      <div className={`w-full sticky z-50 ${stickyStyles} left-0 w-full font-bold text-grey-100 text-left rounded-t bg-grey-900`}>
        <div className="flex w-full items-center pt-2 pb-3 px-2">
          <div className="font-header font-semibold text-md text-green mr-1">
            {user.type === "DealerUser" && t("Current Units")}
          </div>
        </div>
        <div className="flex w-full items-center pb-2 px-2">
          {((currentScope && currentScope !== "current") || dealerUnitsSearchBarValue || user.type === 'AccountManager') &&
            (
              <div className="relative w-8 h-8 flex-shrink-0 mr-2">
                <div className="absolute inset-0 flex items-center justify-center" data-testid={unitsFetching ? "loading-spinner" : "undo-filter"}>
                  {unitsFetching && user.type === 'AccountManager' ? (
                    <Spinner color='green' height='25px'/>
                  ) : (
                    <FaUndoAlt className="cursor-pointer text-grey-300" onClick={handleReset} style={{ fontSize: '25px', width: '25px', height: '25px' }} />
                  )}
                </div>
              </div>
            )
          }
          <div className="grow flex items-center">
            <SearchBar onChange={setDealerUnitsSearchBarValue} value={dealerUnitsSearchBarValue} />
            {user?.type === "AccountManager" &&
              <IoFilterSharp
                data-testid='filter-button'
                onClick={onOpen}
                style={{ cursor: "pointer" }}
                className="ml-2 text-2xl text-grey-300"
                color=""
              />
            }
          </div>
        </div>
      </div>

      {user?.type === "AccountManager" && <UnitsFilterByDrawer isOpen={isOpen} onClose={onClose}/>}
      {user?.type === "AccountManager" && <UnitFilterIndicators onClose={onClose}/>}

      {unitsBody()}
    </div>
  )
}
