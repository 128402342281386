import useStore from "../../../store"
import { useNavigate } from "react-router-dom"
import formatter from "../../../constants/currencyFormatter"
import HighlightMatch from "../../../utilities/HighlightMatch"
import UnitName from "./UnitName"
import UnitStatus from "./UnitStatus"
import UnitPayoffRefloor from "./UnitPayoffRefloor"
import UnitRowQuickPayoff from "./UnitRowQuickPayoff"
import { useIsMobile } from "../../../hooks/useIsMobile"

export default function UnitRow({unit, matchedAttribute, filter}) {
  const navigate = useNavigate()
  const unitRowStyle = useStore((state) => state.unitRowStyle)
  const setDashboardUnitId = useStore((state) => state.setDashboardUnitId)
  const dashboardUnitId = useStore((state) => state.dashboardUnitId)
  const isMobile = useIsMobile()
  const setDealerDashboardSidePanel = useStore((state) => state.setDealerDashboardSidePanel)
  const setBreadcrumbTrail = useStore((state) => state.setBreadcrumbTrail)
  const breadcrumbTrail = useStore((state) => state.breadcrumbTrail)

  const handleClick = () => {
    if (isMobile) {
      const newBreadcrumbTrail = [
        ...breadcrumbTrail,
        {
          label: unit?.attributes.name,
          path: `/unit/${unit.id}`
        }
      ]
      setBreadcrumbTrail(newBreadcrumbTrail)
      navigate(`/unit/${unit.id}`)
    } else {
      setDashboardUnitId(unit.id)
      setDealerDashboardSidePanel(null)
    }
  }

  return (
    <UnitRowQuickPayoff unit={unit}>
      <div
        className={`flex w-full py-2 px-2 lg:hover:bg-grey-700 hover:cursor-pointer text-grey-300 text-left items-center border-b-2 border-grey-700 ${dashboardUnitId === unit.id ? 'bg-grey-700' : ''}`}
        onClick={handleClick}
      >
        <div className="flex flex-col w-1/2 md:w-2/3">
          {<UnitName unit={unit} filter={filter} />}
          {unitRowStyle === 'lg' && <UnitStatus unit={unit} matchedAttribute={matchedAttribute} filter={filter} />}
          {unitRowStyle === 'md' &&
            <div className="text-xs">
              <HighlightMatch str={formatter.format(unit.attributes.payoff)} match={filter} />
            </div>
          }
        </div>
        <div className="flex justify-end w-1/2 md:w-1/3">
          {unitRowStyle !== 'lg' && <UnitStatus unit={unit} matchedAttribute={matchedAttribute} filter={filter} />}
          {unitRowStyle === 'lg' && <UnitPayoffRefloor unit={unit} filter={filter} />}
        </div>
      </div>
    </UnitRowQuickPayoff>
  )
}