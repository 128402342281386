import { useTranslation } from "react-i18next"
import { 
  CometChatConversationsWithMessages,
  ConversationsConfiguration,
  ConversationsStyle,
  MessagesConfiguration,
  MessagesStyle
} from "@cometchat/chat-uikit-react"


export default function SupportChat() {
  const { t } = useTranslation()

  const conversationsStyle = new ConversationsStyle({
    width: "100%",
    height: "90vh",
    background: "",
  });

  const messagesStyle = new MessagesStyle({
    width: "100%",
    height: "90vh",
    background: "",
  });

  return(
    <div className="w-full h-full grow">
      <CometChatConversationsWithMessages
        conversationsConfiguration={
          new ConversationsConfiguration({
            conversationsStyle: conversationsStyle,
          })
        }
        messagesConfiguration={
          new MessagesConfiguration({
            messagesStyle: messagesStyle
          })
        }
        messageText="Click on Chat" 
      />
    </div>
    )
}