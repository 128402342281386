import ClickableRow from "../common/ClickableRow"
import { useTranslation } from "react-i18next"
import useStore from "../../store"
import firebaseApp from "../../configs/firebase"
import { getMessaging, getToken } from "firebase/messaging"
import { useEffect } from "react"

export default function EnablePushNotifications() {
  const { t } = useTranslation()
  const createPushNotificationDevice = useStore((state) => state.createPushNotificationDevice)
  const pushNotificationToken = useStore((state) => state.pushNotificationToken)

  useEffect(() => {
    const messaging = getMessaging(firebaseApp)

    getToken(messaging, {
      vapidKey: 'BLonJ1SiUtY6AGdtY-LRHMzRJbMKlvSsW5eqHv8um54Pa1ajh1afd3WdXGY7KwYVu8xm_lxsexO9ozoVdAmh4Dw'
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken)
          useStore.setState({ pushNotificationToken: currentToken })
        } else {
          console.log("No registration token available. Request permission to generate one.")
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err)
      })
  }, [])

  const handleEnableNotifications = () => {
    createPushNotificationDevice({ device_token: pushNotificationToken })
  }

  return(
    <>
      <ClickableRow
        text={t('Enable Push Notifications')}
        onClick={handleEnableNotifications}
      />
    </>
  )
}