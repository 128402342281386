import useStore from "../../store"
import { useTranslation } from "react-i18next"
import { liveSupportEnabled } from '../../utilities/featureFlags'
import { FaUser, FaHome, FaBars, FaBell, FaExclamationTriangle, FaRegQuestionCircle, FaHandshake } from 'react-icons/fa'
import { FaMoneyBillTrendUp } from "react-icons/fa6"
import { TbClockDollar } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import fxLogo from '../../../public/fpx-logo.png'
import SideBarItem from "./SideBarItem"
import { AiOutlineAudit } from "react-icons/ai"

export default function SideBar() {
  const showSidebar = useStore((state) => state.showSidebar)
  const setShowSidebar = useStore((state) => state.setShowSidebar)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useStore((state) => state.user)

  return (
    <div>
      <div
        className={`hidden lg:block fixed ${showSidebar ? 'left-0' : '-left-96'}
        top-0 h-screen w-80 z-50 bg-black transition-all duration-500`}
        data-testid="sidebar"
      >
        <div className="flex flex-col w-full px-4">
          <div className="flex items-center mt-2 mb-3 pl-4">
            <FaBars
              className="mr-6 hover:cursor-pointer hover:text-grey-100 text-grey-300"
              size={20}
              onClick={() => setShowSidebar(false)}
              data-testid="hamburger"
            />
            <div
              onClick={() => navigate('/')}
              data-testid='fx-logo'
            >
              <img src={fxLogo} alt="Floorplan Xpress" className="h-12 hover:cursor-pointer" />
            </div>
          </div>

          <SideBarItem
            link={'/'}
            text={t('Dashboard')}
            icon={<FaHome className="mr-6" size={20} />}
          />
          <SideBarItem
            link={'/account'}
            text={t('Account')}
            icon={<FaUser className="mr-6" size={20} />}
          />
          { user?.type === 'DealerUser' &&
            <>
              <SideBarItem
                link={'/transfers'}
                text={t('Transaction History')}
                icon={<TbClockDollar className="mr-6" size={20} />}
              />
              <SideBarItem
                link={'/customer_support'}
                text={t('Support')}
                icon={<FaRegQuestionCircle className="mr-6" size={20} />}
              />
            </>
          }
          { user?.type === 'AccountManager' &&
            <>
              <SideBarItem
                link={'/risk'}
                text={'Risk Mitigation'}
                icon={<FaExclamationTriangle className="mr-6" size={20}/>}
              />
              <SideBarItem
                link={'/reminders'}
                text={t('Reminders')}
                icon={<FaBell className="mr-6" size={20} />}
              />
              <SideBarItem
                link={'/business_dev'}
                text={'Business Development'}
                icon={<FaMoneyBillTrendUp className="mr-6" size={20}/>}
              />
              <SideBarItem
                link={'/audits'}
                text={'Unreconciled Field Audits'}
                icon={<AiOutlineAudit className="mr-6" size={20}/>}
              />
              {
                liveSupportEnabled &&
                  <SideBarItem 
                    link={'/support_dashboard'}
                    text={'Support Dashboard'}
                    icon={<FaHandshake className="mr-6" size={20}/>}
                  />
              }
              
            </>
          }
        </div>
      </div>

      <div
        className={`hidden lg:${showSidebar ? 'block opacity-50' : 'block invisible opacity-0'}
        fixed top-0 left-0 z-40 h-screen w-screen bg-black transition-all duration-500`}
        onClick={() => setShowSidebar(false)}
        data-testid="dimmer"
      />
    </div>
  )
}