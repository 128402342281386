const createUserSlice = (set, get) => ({
  graphRange: '1Y',
  isGraphHappy: false,
  isOnline: true,
  showSidebar: false,
  appMessage: null,
  hideGraph: true,
  unitRowStyle: 'sm',
  collapsedSections: {
    dealersWithRepos: true,
    dealersWithReviews: true,
    soldUnits: true,
    unitsPastDue: true,
    dealersWithFieldAudits: true,
    untouchedFortyDays: true
  },
  actionButton: null,
  topNavHidden: false,
  navBarHidden: false,
  userLocation: { latitude: null, longitude: null },
  breadcrumbTrail: [{ label: 'Dashboard', path: '/' }],
  setBreadcrumbTrail: (trail) => set({ breadcrumbTrail: trail }),
  resetBreadcrumbTrail: () => set({ breadcrumbTrail: [{ label: 'Dashboard', path: '/' }] }),
  setGraphRange: (range) => set((state) => ({ graphRange: range })),
  setIsGraphHappy: (range) => set((state) => ({ isGraphHappy: range })),
  setIsOnline: (isOnline) => set((state) => ({ isOnline: isOnline })),
  setShowSidebar: (show) => set((state) => ({ showSidebar: show })),
  setErrMessage: (message, buttonOptions = null) => set((state) => ({ appMessage: { message: message, buttonOptions: buttonOptions, type: 'error' }})),
  setAlertMessage: (message, buttonOptions = null) => set((state) => ({ appMessage: { message: message, buttonOptions: buttonOptions, type: 'alert' }})),
  setSuccessMessage: (message, buttonOptions = null) => set((state) => ({ appMessage: { message: message, buttonOptions: buttonOptions, type: 'success' }})),
  resetMessages: () => set((state) => ({ appMessage: null })),
  toggleHideGraph: () => set((state) => ({ hideGraph: !state.hideGraph })),
  setUnitRowStyle: (style) => set(() => ({ unitRowStyle: style })),
  toggleCollapsedSection: (section) => set((state) => ({
    collapsedSections: {
      ...state.collapsedSections,
      [section]: !state.collapsedSections[section]
    }
  })),
  resetCollapsedSections: () => set((state) => ({
    collapsedSections: {
      dealersWithRepos: true,
      dealersWithReviews: true,
      soldUnits: true,
      unitsPastDue: true,
      dealersWithFieldAudits: true,
      untouchedFortyDays: true,
      unreconciledFieldAudits: true
    }
  })),
  setActionButton: (actionButton) => set((state) => ({ actionButton: actionButton })),
  setTopNavHidden: (topNavHidden) => set((state) => ({ topNavHidden: topNavHidden })),
  setNavBarHidden: (navBarHidden) => set((state) => ({ navBarHidden: navBarHidden })),
  setUserLocation: (location) => set((state) => ({ userLocation: location }))
})

export default createUserSlice