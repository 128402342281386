import useStore from "../../../store"
import { useEffect, useState } from "react"
import NotesRow from "./NotesRow"
import NoteDetails from "./NoteDetails"
import NoteForm from "./NoteForm"
import Pagination from "../../common/Pagination"
import { FaPlusCircle } from "react-icons/fa"
import SlideInInfoPage from "../../common/SlideInInfoPage"
import HeaderWithCreateIcon from "../../common/HeaderWithCreateIcon"

// Notes Body type is either "dealers", "units", or "audits"
export default function NotesBody({ type, id }) {
  const notes = useStore((state) => state.notes)
  const setNotesPage = useStore((state) => state.setNotesPage)
  const { currentPage, lastPage, dataTotal, totalPages } = useStore((state) => state.notesPaginationData)
  const [selectedNote, setSelectedNote] = useState(null)
  const [showNoteForm, setShowNoteForm] = useState(false)

  useEffect(() => {
    setSelectedNote(null)
  }, [notes])

  const handlePageClick = (event) => {
    setNotesPage(event.selected + 1)
  }

  const handleClick = () => {
    setShowNoteForm(true)
  }

  const onBack = () => {
    setSelectedNote(null)
    setShowNoteForm(false)
  }

  return(
    <div className="flex flex-col w-full bg-grey-900 p-2">
      <HeaderWithCreateIcon
        selectedItem={selectedNote}
        onClick={handleClick}
        title={'Notes'}
        dataTestId={'create-note'}
        showForm={showNoteForm}
      />
      {selectedNote || showNoteForm ?
        <SlideInInfoPage onBack={onBack}>
          {selectedNote ?
            <NoteDetails note={selectedNote} />
           :
            <NoteForm type={type} id={id} setShowNoteForm={setShowNoteForm} />
          }
        </SlideInInfoPage>
        :
        <>
          {notes.map((n) => (
            <NotesRow key={n.id} note={n} onClick={() => setSelectedNote(n)} />
          ))}
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            dataTotal={dataTotal}
            handlePageClick={handlePageClick}
            totalPages={totalPages}
          />
        </>
      }
    </div>
    )
}